import React from 'react';

import GlobalLayout from '../components/GlobalLayout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <GlobalLayout>
    <Seo title="404: Not found" />
    <div className="o-page-pad">
      <div className="c-post-body">
        <h1>404. PAGE NOT FOUND</h1>
        <p>Sorry, this page could not be found.</p>
      </div>
    </div>
  </GlobalLayout>
);

export default NotFoundPage;
